<template>
  <!-- 活动预报管理 -->
  <div class="page-container">
    <div class="page-top-tool">
      <!-- <el-button type="success" icon="el-icon-plus" size="mini">新增网点</el-button> -->
      <div class="right-search">
        <span>所属地区：</span>
        <el-select v-model="area" clearable filterable :disabled="loading" placeholder="请选择" size="mini" style="width: 160px;">
          <el-option
            v-for="item in areaList"
            :key="item.id"
            :label="item.name"
            :value="item.name">
          </el-option>
        </el-select>
        <span style="margin-left: 20px;">团队经理：</span>
        <el-select v-model="fcode" placeholder="请选择" :disabled="loading" filterable clearable size="mini" style="width: 180px;">
          <el-option
            v-for="item in fnameOptions"
            :key="item.empcode"
            :label="`${item.empname} ${item.emparea ? '（'+item.emparea+'）' : ''}`"
            :value="item.empcode">
          </el-option>
        </el-select>
        <span style="margin-left: 10px">月份：</span>
        <el-date-picker
          v-model="month"
          type="month"
          size="mini"
          :clearable="false"
          :disabled="loading"
          placeholder="选择月份">
        </el-date-picker>
        <el-button type="primary" icon="el-icon-search" size="mini" :disabled="loading" style="margin: 0px 20px;" @click="onSearch">搜索</el-button>
      </div>
      <div>
        <el-radio-group v-model="activeState" :disabled="loading" size="mini">
          <!-- <el-radio-button label="0">所有活动</el-radio-button> -->
          <el-radio-button label="1">正常活动</el-radio-button>
          <el-radio-button label="2">已取消活动</el-radio-button>
        </el-radio-group>
        <el-button type="success" size="mini" icon="el-icon-download" v-if="!loading" style="margin-left: 15px;" @click="exportMonthData">导出本月数据</el-button>
      </div>
    </div>
    <el-table
      v-loading="loading"
      element-loading-text="数据加载中"
      element-loading-spinner="el-icon-loading"
      :data="tableData"
      style="width: 100%; font-size: 12px; margin: 20px 0px;"
      :header-cell-style="tableHeaderColor"
    >
      <el-table-column
        prop="fname"
        label="团队经理">
      </el-table-column>
      <el-table-column
        prop="area"
        label="所属地区">
      </el-table-column>
      <el-table-column
        prop="month"
        label="活动月份">
      </el-table-column>
      <el-table-column
        prop="activitynum"
        label="活动总场次">
      </el-table-column>
      <el-table-column label="活动状态">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.state == '1'" type="success" size="mini">正常</el-tag>
          <el-tag v-else type="info" size="mini">已取消</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button type="text" size="mini" icon="el-icon-view" @click="showDetails(scope)">查看详情</el-button>
          <el-button type="text" size="mini" icon="el-icon-delete" @click="removePreactive(scope)">删除活动</el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页 -->
    <el-pagination
      @current-change="handleCurrentChange"
      :current-page.sync="currentPage"
      :page-size="pageSize"
      hide-on-single-page
      layout="total, prev, pager, next"
      :total="total">
    </el-pagination>

    <!-- 活动详情弹框 -->
    <el-dialog title="活动详情" :visible.sync="dialogTableVisible" @close="dialogTableClose">
      <div class="active-detail-dialog">
        <el-table :data="gridData">
          <el-table-column property="month" label="活动月份" width="100"></el-table-column>
          <el-table-column property="fname" label="团队经理" width="100"></el-table-column>
          <el-table-column property="sname" label="规划师" width="100"></el-table-column>
          <el-table-column property="num" label="活动场次" width="100"></el-table-column>
          <el-table-column property="address" label="收货地址"></el-table-column>
          <el-table-column property="sphone" label="规划师电话" width="150"></el-table-column>
        </el-table>
      </div>
    </el-dialog>

    <!-- 导出本月数据弹框 -->
    <el-dialog :title="monthDataTitle" :visible.sync="dialogMonthDataVisible" @close="dialogTableClose">
      <div class="active-detail-dialog">
        <el-table v-loading="monthTableLoading" id="tableId" :data="monthTableData">
          <el-table-column property="month" label="活动月份" width="100"></el-table-column>
          <el-table-column property="fname" label="团队经理" width="100"></el-table-column>
          <el-table-column property="sname" label="规划师" width="100"></el-table-column>
          <el-table-column property="num" label="活动场次" width="100"></el-table-column>
          <el-table-column property="area" label="所属地区" width="100"></el-table-column>
          <el-table-column property="address" label="收货地址"></el-table-column>
          <el-table-column property="sphone" label="规划师电话" width="150"></el-table-column>
        </el-table>
      </div>
      <div class="active-detail-dialog-bottom">
        <el-button type="success" icon="el-icon-download" :disabled="monthTableData.length <= 0" size="mini" @click="onDownloadExcel">一键下载</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import FileSaver from "file-saver"
import * as XLSX from "xlsx"
export default {
  name: 'ActivityPage',
  data() {
    return {
      fnameOptions: [], // 可选经理
      employeeList: [], // 团队原数据
      allStoreList: [], // 所有网点
      areaList: [], // 所有地区
      area: '', // 选择地区
      fcode: '', // 选择经理
      month: '', // 月份
      activeState: '1', // 活动状态，0所有活动，1正常活动，2已取消活动
      tableData: [],
      currentPage: 1,
      pageSize: 10,
      total: 0,
      loading: false,
      dialogTableVisible: false, // 活动详情弹框状态
      dialogMonthDataVisible: false, // 导出本月数据弹框状态
      gridData: [], // 活动详情数组
      monthTableData: [], // 要导出的本月数据
      monthDataTitle: '', // 导出本月数据弹框标题
      monthTableLoading: false
    };
  },
  watch: {
    employeeList: {
      deep: true,
      immediate: true,
      handler(val) {
        this.fnameOptions = val.filter(item => {
          return item.role == '1'
        })
      }
    },
    allStoreList: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val.length > 0) {
          this.fnameOptions.forEach(f => {
            val.forEach(store => {
              if (f.empcode == store.fcode) {
                f.emparea = store.area
              }
            })
          })
        }
      }
    },
    activeState() {
      this.currentPage = 1
      this.getData()
    }
  },
  mounted() {
    this.month = new Date()
    this.initData()
    // this.getData()
  },

  methods: {
    async initData() {
      this.loading = true
      const empResp = await this.$http({
        method: 'post',
        url: 'employee_findall'
      })
      if (empResp.errno == 0) {
        this.employeeList = empResp.data.data
      } else {
        this.$message.error('员工信息查询失败')
      }

      const storeResp = await this.$http({
        method: 'post',
        url: 'store_findall'
      })
      if (storeResp.errno == 0) {
        this.allStoreList = storeResp.data.data
      } else {
        this.$message.error('网点加载失败')
      }

      const areaResp = await this.$http({
        method: 'post',
        url: 'area_findall'
      })
      if (areaResp.errno == 0) {
        this.areaList = areaResp.data.data
      } else {
        this.$message.error('地区加载失败')
      }

      this.getData()
    },
    getData() {
      if (!this.month) {
        this.$message.warning('请选择要查询的活动月份！')
        return
      }
      this.loading = true
      const year = this.month.getFullYear()
      const month = this.month.getMonth() + 1
      this.$http({
        method: 'post',
        url: 'preactivity_find',
        params: {
          fcode: this.fcode,
          month:  `${year}/${month}`,
          // state: this.activeState == '0' ? '' : this.activeState,
          state: this.activeState,
          page: this.currentPage,
          pageSize: this.pageSize
        }
      })
      .then(res => {
        this.loading = false
        if (res.errno == 0) {
          const { data, page, pageSize, count } = res.data
          this.tableData = data
          this.currentPage = page
          this.pageSize = pageSize
          this.total = count
          // if (this.tableData.length > 0) {
          //   this.addAreaFiled()
          // }
          this.tableData.forEach(item => {
            this.allStoreList.forEach(store => {
              if (store.fcode == item.fcode) {
                item.area = store.area
              }
            })
          })
        } else {
          this.$message.error('活动查询异常')
        }
      })
      .catch(err =>{
        this.loading = false
        this.$message.error('活动查询异常')
        console.error('活动查询异常：',err.message);
      })
    },
    // 查询的数据添加地区字段
    addAreaFiled() {
      this.tableData.forEach(data => {
        for (let i = 0; i < this.allStoreList.length; i++) {
          const item = this.allStoreList[i]
          if (store.fcode == item.fcode) {
            data.area = item.area
          }
        }
      })
    },
    tableHeaderColor({row, column, rowIndex, columnIndex}) {
      return 'background-color: #F2F2F2;color:#101010;'
    },
    onSearch() {
      this.currentPage = 1
      if (this.area && this.fcode) {
        this.queryActiveByArea()
      } else {
        this.getData()
      }
    },
    async queryActiveByArea() {
      this.loading = true
      const year = this.month.getFullYear()
      const month = this.month.getMonth() + 1
      const resp = await this.$http({
        method: 'post',
        url: 'preactivity_findall'
      })

      if (resp.errno != 0) {
        return
      }

      this.tableData = resp.data.data
      this.addAreaFiled()
      if (this.area) {
        this.tableData = this.tableData.filter(item => item.area == this.area)
      }
      if (this.fcode) {
        this.tableData = this.tableData.filter(item => item.fcode == this.fcode)
      }
      if (this.month) {
        this.tableData = this.tableData.filter(item => item.month == `${year}/${month}`)
      }
      this.currentPage = 1
      this.total = 0
      this.loading = false
    },
    handleCurrentChange(page) {
      this.currentPage = page
      this.getData()
    },
    // 查看活动详情
    showDetails({ row, $index }) {
      const details = JSON.parse(row.details)
      details.forEach(item => {
        item.fcode = row.fcode
        item.fname = row.fname
        item.month = row.month
        this.employeeList
        for (let i = 0; i < this.employeeList.length; i++) {
          const emp = this.employeeList[i]
          if (emp.empcode == item.scode) {
            item.sphone = emp.phone
            item.address = emp.address
            break
          }
        }
      })
      this.gridData = details
      this.dialogTableVisible = true
    },
    // 删除活动
    removePreactive({ row, $index }) {
      const message = `此操作将删除 ${row.fname} ${row.month} 的活动，是否继续？`
      this.$confirm(message, '提示', {
        confirmButtonText: '继续',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$confirm('删除后数据将不能恢复，再次确定要删除吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.loading = true
          this.$http({
            method: 'post',
            url: 'preactivity_delete',
            params: {
              id: row.id
            }
          })
          .then(res => {
            if (res.errno == 0) {
              this.$message.success('删除成功')
              this.getData()
            } else {
              this.$message.error('删除失败')
            }
            this.loading = false
          })
          .catch(err => {
            this.loading = false
            this.$message.error('网络异常，删除失败！')
          })
        }).catch(() => {})
      }).catch(() => {})
    },
    // 活动详情弹框关闭事件
    dialogTableClose() {
      this.gridData = []
      this.monthTableData = []
      this.monthDataTitle = ''
    },
    // 导出本月数据按钮事件
    exportMonthData() {
      const year = this.month.getFullYear()
      const month = this.month.getMonth() + 1
      this.monthDataTitle = `${year}年${month}月填报总数据`
      this.dialogMonthDataVisible = true
      this.monthTableData = []

      this.monthTableLoading = true
      this.$http({
        method: 'post',
        url: 'preactivity_findall',
        params: {
          month:  `${year}/${month}`,
          state: '1'
        }
      })
      .then(res => {
        this.monthTableLoading = false
        if (res.errno == 0) {
          res.data.data.forEach(item => {
            if (item.details) {
              const details = JSON.parse(item.details)
              details.forEach(obj => {
                obj.month = item.month
              })
              this.monthTableData = this.monthTableData.concat(details)
              this.monthTableData.forEach(obj => {
                this.employeeList.forEach(obj2 => {
                  if (obj.scode == obj2.empcode) {
                    obj.address = obj2.address
                  }
                })
              })
            }
          })
        } else {
          this.$message.error('活动查询异常')
        }
      })
      .catch(err =>{
        this.monthTableLoading = false
        this.$message.error('活动查询异常')
        console.error('活动查询异常：',err.message);
      })
    },
    // 下载Excel
    onDownloadExcel() {
      //转换成excel时，使用原始的格式
				var xlsxParam = { raw: true };
				let fix = document.querySelector(".el-table__fixed");
				let wb;
        //判断有无fixed定位，如果有的话去掉，后面再加上，不然数据会重复
				if (fix) {
				  wb = XLSX.utils.table_to_book(
				  document.querySelector("#tableId").removeChild(fix),xlsxParam
				  );
				  document.querySelector("#tableId").appendChild(fix);
				} else {
				  wb = XLSX.utils.table_to_book(document.querySelector("#tableId"),xlsxParam);
				}
				var wbout = XLSX.write(wb, {
          bookType: "xlsx",
          bookSST: true,
          type: "array",
				});
				try {
				FileSaver.saveAs(
					new Blob([wbout], { type: "application/octet-stream" }),`${this.monthDataTitle}.xlsx`); //文件名
				} catch (e) {
				if (typeof console !== "undefined") console.log(e, wbout);
				}
				return wbout
    }
  },
};
</script>

<style lang="scss" scoped>
.page-container {
  background: #fff;
  height: 100%;
  width: 100%;
  border-radius: 3px;
  box-sizing: border-box;
  padding: 20px 25px;
  overflow-y: scroll;

  .page-top-tool {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
  }
}

/* 修改滚动条样式 */
.page-container::-webkit-scrollbar {
	width: 4px;
}
.page-container::-webkit-scrollbar-thumb {
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
	background: rgba(0, 0, 0, 0.2);
}
::v-deep .el-dialog {
  width: 70%;
  height: 640px;
  top: -20px;
}

.active-detail-dialog {
  height: 480px;
  overflow-y: scroll;
}
.active-detail-dialog-bottom {
  margin-top: 20px;
  text-align: right;
}

/* 修改滚动条样式 */
.active-detail-dialog::-webkit-scrollbar {
	width: 4px;
}
.active-detail-dialog::-webkit-scrollbar-thumb {
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
	background: rgba(0, 0, 0, 0.2);
}

</style>